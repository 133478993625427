import { useEffect, useState} from 'react';
import './Activation.scss';
import activatioImg from "./Assets/images/activation_image.svg";
import newgenLogo from "./Assets/images/NewgenONE.svg";
import axios from "axios";

export default function Activation() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const emailID=atob(urlParams.get('emailId'));
    const activationToken=atob(urlParams.get('activationToken'));
    const tenantId=atob(urlParams.get('tenantId'))
    const [ errorInActivation ,setErrorInActivation]=useState(false)

    const [spinner , setSpinner]=useState(true)
    useEffect(()=>{
        ActivateTenant()
      },[])

    const ActivateTenant=()=>{
        let config={
            headers:{
              "org":"ECM",
              "Content-Type":"application/json",
              "tenantid":tenantId,
            }
          }
          axios.get(process.env.REACT_APP_MICROSERVICE_URL  + `/tenantservice/tenants/activateAccount/${emailID}/${activationToken}` , config)
            .then((response) => {
               console.log(response)
               setSpinner(false);
            })
            .catch((error) => {
              console.log(error);
              setErrorInActivation(true)
            });
    }

  return (
      <div className="activate-container-div" >
      <div className="nav-bar">
        <div class="app-header">
          <img className='newgen-logo'  src={newgenLogo}></img>
        </div>
      </div>
        {spinner ? <div className='spinner-container'>
          <div className="spinner"> </div>
          <div className='spinner-text'>{errorInActivation ?"Something went wrong, close the tab and try again ":"Please wait while we activate your account"}</div>
        </div>
          
        :<div className="activate-container">
                  <img
                    src={activatioImg}
                    alt="Architectural Diagram"
                    className='content-logo'
                  ></img>
                  <div className='activation-text'>Account Activated Successfully</div>
                  <button className='getStart'><a className="logIn" href={process.env.REACT_APP_WEB_BASE_URL + "/content-cloud-admin/login"}>Get Started</a></button>
                
        </div>}
      </div>
  )
}
