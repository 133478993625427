
import Activation from './Activation';
import './App.scss';
import Microsite from './Microsite';
import {Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Routes> 
      <Route path="/" element={<Microsite/>}/>
      <Route path="/activation" element={<Activation/>}/>
      </Routes> 
    </div>
  );
}

export default App;
