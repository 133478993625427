import newgenLogo from "./Assets/images/NewgenONE.svg";
import graphic1 from "./Assets/images/graphic1.svg";
import tick from "./Assets/icons/checkbox-circle-line.svg";
import "./Microsite.scss";
import { useRef, useState ,useEffect} from "react";
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";

export default function MyFile() {
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [message, setMessage] = useState();
  const [showMore, setShowMore] = useState(false);
  const [mailsent , setMailsent]=useState(false)
  
  const [faqsActive, setFaqsActive] = useState(false);
  const [contactUsActive, SetContactUsActive] = useState(false);

  const faqsRef = useRef(null);
  const contactUsRef = useRef(null);

  const handleContactUs = () => {
    contactUsRef.current?.scrollIntoView({ behavior: "auto" });
    SetContactUsActive(true);
    setFaqsActive(false);
  };
  const handleFaqs = () => {
    faqsRef.current?.scrollIntoView({ behavior: "auto" });
    setFaqsActive(true);
    SetContactUsActive(false);
  };

  const [faqsData, setFaqsData] = useState([
    {
      id: 1,
      showmore: true,
      flag: false,
      que: "Is there a free trial for NewgenONE Content Cloud?",
      ans: "Yes. You can register for a free 14-day trial of NewgenONE Content Cloud. Simply visit our Plans and Pricing page to explore various plans and sign up for the one that meets your needs. No credit card required, and you can choose when to activate a monthly subscription.",
    },
    {
      id: 2,
      flag: false,
      showmore: true,
      que: "How do I sign up for NewgenONE Content Cloud?",
      ans: "You can get your NewgenONE Content Cloud account then go to login page and click on “Sign Up”.",
    },
    {
      id: 3,
      flag: false,
      showmore: true,
      que: "How much space do I get?",
      ans: "Personal user accounts for NewgenONE Content Cloud offer 1GB of storage. Developer, Business, and Enterprise plans offer increased storage, file versions and handling of larger file sizes.",
    },
    {
      id: 4,
      flag: false,
      showmore: true,
      que: "How do I change my password?",
      ans: "To change your NewgenONE Content Cloud password then go to login page and click on “Forgot Password”.",
    },
  ]);
  const faqsData2 = [
    {
      id: 5,
      flag: false,
      //showmore:showMore,
      que: "I forgot my password. How do I reset it?",
      ans: "If you forgot or want to change your password then go to login page and click on “Forgot Password”.",
    },
    {
      id: 6,
      flag: false,
      //showmore:showMore,
      que: "What if I need help with NewgenONE Content Cloud?",
      ans: "If you run into an error or anything that you’d like help with, send your query to us by visiting “Contact Us”.",
    },
    {
      id: 7,
      flag: false,
      //showmore:showMore,
      que: "How can I test an API?",
      ans: 'It is possible to test an API from the Documentation & Try-out or you can use Postman tool also. When looking at the details of an API you will see a table of the services and operations contained in the API. This will show what method they are (GET, POST, PUT, DELETE) and what path the api uses. If you click on "Try" button which enables you to try that API. API requires certain parameters which are specified at the top of the "Documentation & Try-out" section.',
    },
    {
      id: 8,
      flag: false,
      //showmore:showMore,
      que: "How do I see my data usage?",
      ans: "You need to login to the system to see the storage used.",
    },
    {
      id: 9,
      flag: false,
      //showmore:showMore,
      que: "Where can i know more about the NewgenONE Content Cloud?",
      ans: "You need to visit “About Product” page.",
    },
  ];
  const faqToggle = (key) => {
    const nw = faqsData.map((item) => {
      if (item.id === key) {
        const temp = !item.flag;
        return { ...item, flag: temp };
      }
      return { ...item };
    });
    setFaqsData(nw);
  };
  const toggleShowMore = () => {
    if (showMore) {
      setShowMore(false);
      const temp = faqsData.filter((item) => item.id < 5);
      setFaqsData(temp);
    } else {
      const temp = faqsData.concat(faqsData2);
      setFaqsData(temp);
      setShowMore(true);
    }
  };
  const sendMessage = () => {
    console.log(fullName, email, phoneNumber, message);
    let req = {
        customerEmail: email,
        customerMobNumber: phoneNumber,
        customerName: fullName,
        query: message,
    };
    let config={
      headers:{
        "org":"ECM",
        "contentType": "text/html",
      }
    }
    axios.post(process.env.REACT_APP_MICROSERVICE_URL  + '/loggingservice/logging/emailForQuery' ,req ,config)
      .then((response) => {
         console.log(response)
         setMailsent(true);
      })
      .catch((error) =>{ 
        console.log(error); 
        setMailsent(false)
      });
    
  };
  const handleScroll = (event) => {
    setFaqsActive(false);
    SetContactUsActive(false);
  };

  const [activeAccess , setActiveAccess]=useState(false)
  useEffect(()=>{
    validateCookieToken()
  },[])

  const validateCookieToken =()=>{
    let config={
      headers:{
        "accessToken":getCookie("accessToken"),
        "org":"ECM",
        "tenantid":getCookie("tenantid"),
        "Content-Type":"application/json",
        "secret":getCookie("secret"),
        "appId":getCookie("appId")
      }
    }
    axios.get(process.env.REACT_APP_MICROSERVICE_URL  + '/appservice/auth/token/validate' , config)
      .then((response) => {
         console.log(response)
         setActiveAccess(true)
      })
      .catch((error) =>{ 
        console.log(error)
        setActiveAccess(false)
      });
      
  }

  const getCookie=(cname)=> {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return (
    <>
      <div className="header">
        <div className="application-logo">
          <img className="newgen-logo" src={newgenLogo}></img>
        </div>
        <div className="header-list">
          <span
            className={faqsActive ? "header-item active" : "header-item"}
            onClick={handleFaqs}
          >
            FAQs
          </span>
          <span
            className={contactUsActive ? "header-item active" : "header-item"}
            onClick={() => handleContactUs()}
          >
            Contact Us
          </span>
          <span className="header-item logIn" >
            { activeAccess ? <a className="logIn" href={`${process.env.REACT_APP_WEB_BASE_URL}/content-cloud-admin/home/metering-dashboard`}> Go to Dashboard </a>
            :<a className="logIn" href= {`${process.env.REACT_APP_WEB_BASE_URL}/content-cloud-admin/login`}>Log In</a>}
            </span>
        </div>
      </div>
      <div className="main-section" onScroll={(event) => handleScroll(event)}>
        <div className="product-info">
          <div className="product-sub">
            <div className="product-sub-title">NewgenONE Content Cloud</div>
            <div className="product-sub-info">
              Secure content management services for developing software
              solutions. We believe in:
            </div>
            <div className="Carousel">
              <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                totalSlides={4}
                className="my-Carousel"
                isPlaying={true}
              >
                <Slider className="catagry">
                  <Slide index={0}>
                    <div className="title">INNOVATE</div>
                    <div className="text">
                      a connected and digital workplace leveraging a
                      cloud-native and microservices architecture
                    </div>
                  </Slide>
                  <Slide index={1}>
                    <div className="title">POWER</div>
                    <div className="text">
                      your Customers with Custom-Built GUI and User Experience
                    </div>
                  </Slide>
                  <Slide index={2}>
                    <div className="title">MANAGE</div>
                    <div className="text">
                      your documents by building engaging web or mobile
                      applications using the REST APIs
                    </div>
                  </Slide>
                  <Slide index={3}>
                    <div className="title">CREATE</div>
                    <div className="text">
                      a connected and digital workplace leveraging a
                      cloud-native and microservices architecture
                    </div>
                  </Slide>
                </Slider>
                <DotGroup circular="true" className="dot-group" />
              </CarouselProvider>
            </div>
          </div>
          <div className="product-sub-img">
            <img className="label" src={graphic1}></img>
          </div>
        </div>
        <div className="try-unlimited-section">
          <div className="free-button" ><a className="free-text" href={`${process.env.REACT_APP_WEB_BASE_URL}/content-cloud-admin/signup`}>Try for free</a></div>
          <div className="refrence-text"> 14 Days Unlimited Access</div>
        </div>
        <div className="product-explain">
          <span className="imp">NewgenONE Content Cloud</span> is a REST-based
          API platform offering, developed on contemporary micro services
          architecture and design patterns. It provides a cloud ready backend
          services suite for business customers to build engaging software
          solutions.
          <div className="product-sub-explain">
            NewgenONE Content Cloud services are developed and hosted on the
            Microsoft Azure cloud platform. These services are architectured and
            designed with best practices of data and application availability.
            The micro services and cloud-native approach delivers security,
            performance, scalability, availability, fault tolerance and disaster
            recovery for high volume production workloads of customer
            organizations.
          </div>
        </div>
        <div className="line-break-heading">
          <div>CLOUD AND DEVELOPER CENTRIC</div>
        </div>
        <div className="centric-aspects">
          <div className="centric-area ">
            <div className="centric-img">
              <img
                className="label"
                src={require("./Assets/images/graphic2.png")}
              ></img>
            </div>
            <div className="centric-explain centric-cloud">
              <div className="centric-header">
                {" "}
                Cloud-Native Architecture & Microservices{" "}
              </div>
              <div className="centric-refrences">
                <div className="centric-refrence">
                  <div className="centric-refrences-icon">
                    <img src={tick}></img>
                  </div>
                  <div className="centric-refrences-text">
                    No upfront hardware/software infrastructure and cost
                    requirements.
                  </div>
                </div>
                <div className="centric-refrence">
                  <div className="centric-refrences-icon">
                    <img src={tick}></img>
                  </div>
                  <div className="centric-refrences-text">
                    Cloud-Native architecture and Microservices for enabling
                    flexibility for high volume production workloads of customer
                    organizations.
                  </div>
                </div>
                <div className="centric-refrence">
                  <div className="centric-refrences-icon">
                    <img src={tick}></img>
                  </div>
                  <div className="centric-refrences-text">
                    Designed for cloud-native security, performance, high
                    availability, fault tolerance, elastic- scalability and
                    disaster recovery business application requirements.
                  </div>
                </div>
                <div className="centric-refrence">
                  <div className="centric-refrences-icon">
                    <img src={tick}></img>
                  </div>
                  <div className="centric-refrences-text">
                    Designed for global customer footprint and auto-failover.
                  </div>
                </div>
                <div className="centric-refrence">
                  <div className="centric-refrences-icon">
                    <img src={tick}></img>
                  </div>
                  <div className="centric-refrences-text">
                    NewgenONE Content Cloud is developed and hosted on Microsoft
                    Azure cloud platform.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="centric-area dev-platform-container">
            <div className="centric-explain centric-area-dev">
              <div className="centric-header">Developer-friendly Platform</div>
              <div className="centric-refrences">
                <div className="centric-refrence">
                  <div className="centric-refrences-icon">
                    <img src={tick}></img>
                  </div>
                  <div className="centric-refrences-text">
                    Open, easy to integrate REST Based APIs to build engaging
                    software solutions.
                  </div>
                </div>
                <div className="centric-refrence">
                  <div className="centric-refrences-icon">
                    <img src={tick}></img>
                  </div>
                  <div className="centric-refrences-text">
                    Developers can choose between NewgenONE Content Cloud API
                    Try-out GUI or the industry-standard Postman Client for REST
                    APIs access.
                  </div>
                </div>
                <div className="centric-refrence">
                  <div className="centric-refrences-icon">
                    <img src={tick}></img>
                  </div>
                  <div className="centric-refrences-text">
                    Cloud hosted platform to connect from anywhere.
                  </div>
                </div>
              </div>
            </div>
            <div className="centric-img">
              <img
                className="label"
                src={require("./Assets/images/graphic3.png")}
              ></img>
            </div>
          </div>
        </div>
        <div className="line-break-heading">
          <div>KEY FEATURES</div>
        </div>
        <div className="features">
          <div className="feature">
            <div className="feature-icon">
              <img
                className="feature1-icon"
                src={require("./Assets/images/feature1.svg").default}
              ></img>
            </div>
            <div className="feature-explain">
              <div className="feature-heading">Folder and File Management</div>
              <div className="feature-text">
                Add or delete folders and files for organizing content
              </div>
            </div>
          </div>
          <div className="feature">
            <div className="feature-icon">
              <img
                className="feature1-icon"
                src={require("./Assets/images/feature2.svg").default}
              ></img>
            </div>
            <div className="feature-explain">
              <div className="feature-heading">User Management</div>
              <div className="feature-text">
                Authenticate users with control on actions through permissions
              </div>
            </div>
          </div>
          <div className="feature">
            <div className="feature-icon">
              <img
                className="feature1-icon"
                src={require("./Assets/images/feature3.svg").default}
              ></img>
            </div>
            <div className="feature-explain">
              <div className="feature-heading">OAuth Security</div>
              <div className="feature-text">
                Authentication and authorization for integrating with NCC
              </div>
            </div>
          </div>
          <div className="feature">
            <div className="feature-icon">
              <img
                className="feature1-icon"
                src={require("./Assets/images/feature4.svg").default}
              ></img>
            </div>
            <div className="feature-explain">
              <div className="feature-heading">Metering Dashboard</div>
              <div className="feature-text">
                Track user's activities and get utilization metrices
              </div>
            </div>
          </div>
          <div className="feature">
            <div className="feature-icon">
              <img
                className="feature1-icon"
                src={require("./Assets/images/feature5.svg").default}
              ></img>
            </div>
            <div className="feature-explain">
              <div className="feature-heading">Advanced Search</div>
              <div className="feature-text">
                Instant searching of words and phrases in the stored documents
              </div>
            </div>
          </div>
          <div className="feature">
            <div className="feature-icon">
              <img
                className="feature1-icon"
                src={require("./Assets/images/feature6.svg").default}
              ></img>
            </div>
            <div className="feature-explain">
              <div className="feature-heading">Micro UI</div>
              <div className="feature-text">
                Interfaces for consuming the microservices based REST APIs
              </div>
            </div>
          </div>
        </div>
        <div className="line-break-heading">
          <div>CUSTOMER VALUE ASPECTS</div>
        </div>
        <div className="customer-value-aspects">
          <div className="value-area">
            <div className="value-explain">
              <div className="value-header">Micro UI</div>
              <div className="value-text">
                For the developer to experience the power of NewgenONE Content
                Cloud REST APIs. We are providing Interfaces for consuming the
                microservices based Rest APIs
              </div>
            </div>
            <div className="value-img micro-app-img">
              <img src={require("./Assets/images/micro-ui-img.png")}></img>
            </div>
          </div>
          <div className="value-area documentation-value-area">
            <div className="value-img micro-app-img">
              <img src={require("./Assets/images/dc.png")}></img>
            </div>
            <div className="value-tryout ">
              <div className="value-header">Documentation & Try-out</div>
              <div className="value-text">
                With NewgenONE Content Cloud REST APIs that enable developers to
                build custom User Experiences & GUIs
              </div>
            </div>
          </div>
          <div className="value-area">
            <div className="value-explain">
              <div className="value-header">Metering Dashboard</div>
              <div className="value-text">
                It presents an overall picture of the product, analysing
                performance and status, with some graphs/tables drills down to
                further details.
              </div>
            </div>
            <div className="value-img micro-app-img">
              <img src={require("./Assets/images/mt-dashboard.png")}></img>
            </div>
          </div>
        </div>
        <div className="line-break-heading" ref={faqsRef}>
          <div>FREQUENTLY ASKED QUESTIONS</div>
        </div>
        <div className="faqs">
          {faqsData?.map((item, key) => (
              <div
                className={item.flag ? "faq faq-border" : "faq"}
                key={item.id}
              >
                <div className="faq-wrapper">
                  <div
                    className="faq-heading"
                    onClick={() => faqToggle(item.id)}
                  >
                    <div className="heading">{item.que}</div>
                    <img
                      className="faq-icon collapsed"
                      src={
                        item.flag === true
                          ? require("./Assets/images/expanded.svg").default
                          : require("./Assets/images/collapsed.svg").default
                      }
                    ></img>
                  </div>

                  {item.flag && <div className="faq-text">{item.ans}</div>}
                </div>
              </div>
            ))}

          <div className="faq-show-more" onClick={() => toggleShowMore()}>
            {showMore ? "Show Less" : "show more"}
          </div>
        </div>
        <div className="line-break-heading" ref={contactUsRef}>
          <div>CONTACT US</div>
        </div>
        <div className="contact">
          <div className="contact-form-div">
            {mailsent ?<div className="sentmail contact-form ">
              <p>Thanks for showing interest in NewgenONE Content Cloud. 
              We have received your message and we'll get back to you shortly.  </p>
                          
              <p>Thanks,</p>
              Team NewgenONE 
              </div> :
            <div className="contact-form">
              <div className="contact-header">
                Connect with an expert from Newgen
              </div>
              <div className="contact-form-group">
                <div className="contact-label">Full Name</div>
                <input
                  placeholder="Please enter your full name"
                  type="text"
                  className="contact-input contact-input-padding inputDiv "
                  onChange={(ev) => setFullName(ev.target.value)}
                ></input>
              </div>
              <div className="contact-form-group">
                <div className="contact-label">Email</div>
                <input
                  placeholder="email@example.com"
                  type="text"
                  className="contact-input contact-input-padding inputDiv "
                  onChange={(ev) => setEmail(ev.target.value)}
                ></input>
              </div>
              <div className="contact-form-group">
                <div className="contact-label">
                  Phone Number
                  <span className="optional">(Optional)</span>
                </div>
                <PhoneInput
                  containerClass="input-cont"
                  country={"in"}
                  countryCodeEditable={false}
                  inputClass="input-class"
                  buttonClass="flag-dropdown-my"
                  placeholder="000000000"
                  enableSearch
                  value={phoneNumber}
                  onChange={(phoneNumber) => setPhoneNumber(phoneNumber)}
                  jumpCursorToEnd={true}
                />
              </div>
              <div className="contact-form-group">
                <div className="contact-label">Your Message</div>
                <textarea
                  placeholder="Please enter your query here"
                  className="contact-textarea contact-input-padding inputDiv ng-pristine ng-valid ng-touched "
                  onChange={(ev) => setMessage(ev.target.value)}
                ></textarea>
              </div>
              <div className={(email?.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) && fullName && message) ?"contact-button" : "disable contact-button"} onClick={() => sendMessage()}>
                Send Message
              </div>
            </div>}
          </div>
          <div className="contact-addresses">
            <div className="contact-addresses-div">
              <div className="address">
                <div className="address-header">United States</div>
                <div className="address-bodies">
                  <div className="address-body address-body1">
                    <div class="title">Newgen Software Inc.</div>
                    <div className="text">1364 Beverly Road, Suite 300,</div>
                    <div className="text">McLean, VA 22101,</div>
                    <div className="text">United States</div>
                    <div className="text">Phone: +1-703-749-2856</div>
                    <div className="text">Phone: +1-703-439-0703</div>
                    <div className="text">generalinfo@newgensoft.com</div>
                  </div>
                  <div className="address-body address-body2">
                    <div class="title">For Sales Inquiry:</div>
                    <div className="text">Phone: +1-202-800-7783</div>
                    <div className="text">info@newgensoft.com</div>
                  </div>
                </div>
              </div>
              <div className="address">
                <div className="address-header">India</div>
                <div className="address-bodies">
                  <div className="address-body address-body1">
                    <div class="title">Newgen Software Inc.</div>
                    <div className="text">A-6, Satsang Vihar Marg,</div>
                    <div className="text">Qutab Institutional Area</div>
                    <div className="text">New Delhi- 110 067, India</div>
                    <div className="text">Phone: +91 11 4077 0100</div>
                    <div className="text">Phone: +91 11 2696 3571</div>
                    <div className="text">Fax: +91 11 2685 6936</div>
                  </div>
                  <div className="address-body address-body2">
                    <div class="title">For Sales Inquiry:</div>
                    <div className="text">Phone: +91 11 4077 3769</div>
                    <div className="text">info@newgensoft.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
